<!--img src="/assets/bg-nhl.jpg" class="bg"/-->

<header>
	<img src="/assets/logo.jpg"/>
	<span>VIP24ALL .com</span>
</header>

<main>
	<header>
		<img src="/assets/mlb-simple.svg"/>
		<img src="/assets/nhl-simple.svg"/>
		<img src="/assets/nfl-simple.svg"/>
		<img src="/assets/nba-simple.svg"/>
	</header>

	<div id="g_id_onload"
		data-client_id="843294256253-fol7i5nl0l119f0f6v91dscjnq3m3gub.apps.googleusercontent.com"
		data-context="signin"
		data-ux_mode="popup"
		data-login_uri="https://vip24all.com/auth"
		data-auto_prompt="false">
	</div>
	<div class="g_id_signin" data-type="standard"></div>
</main>
